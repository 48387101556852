export const currencyFormatter = (value: number) => {
  return (Math.round(value * 100) / 100).toLocaleString();
};

export const truncateText = (value: string, maxLength: number) => {
  return value.length > maxLength
    ? value.slice(0, maxLength - 1) + "..."
    : value;
};

// Date format
export function forMatDate(date: string) {
  // Aug 16, 2023
  let monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const daysArr = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  let fulldate = new Date(date);
  const day = fulldate.getDay();
  const dayName = daysArr[day];
  const dayDate = fulldate.getDate();
  let month = fulldate.getMonth();
  const year = fulldate.getFullYear();
  let monthName = monthsArr[month];
  return `${dayName} ${monthName} ${dayDate}, ${year}`;
}


export const formatDateTime = (date: string) => {

  // Create a Date object by passing the timestamp string
  const timestamp = new Date(date);

  // Extract hours, minutes, and seconds from the Date object
  const hours = timestamp.getHours();
  const minutes = timestamp.getMinutes();
  //const seconds = timestamp.getSeconds();

  // Determine if it's AM or PM
  const amOrPm = hours >= 12 ? 'pm' : 'am';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Add leading zero to single-digit minutes
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Output the time
  return ` ${formattedHours}:${formattedMinutes}${amOrPm}`;
}


export const getDateFormat = (_arg: Date) => {
  const specificDate = new Date(_arg);
  const formattedSpecificDate = specificDate.toLocaleDateString('en-GB');

  return formattedSpecificDate;
}


//Handles overnight hours (e.g., 22:00 to 06:00)
  //Handles day hours (e.g., 12:00 to 13:00)
  export const checkOpeningHour = (openingTime: string, closingTime: string): boolean => {
  // Validate input format (HH:MM)
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  if (!timeRegex.test(openingTime) || !timeRegex.test(closingTime)) {
    throw new Error("Invalid time format. Expected HH:MM.");
  }

  const now = new Date();
  const currentTime = now.getHours() * 60 + now.getMinutes();

  const [openHours, openMinutes] = openingTime.split(':').map(Number);
  const openingMinutes = openHours * 60 + openMinutes;

  const [closeHours, closeMinutes] = closingTime.split(':').map(Number);
  const closingMinutes = closeHours * 60 + closeMinutes;

  if (closingMinutes < openingMinutes) {
    return currentTime >= openingMinutes || currentTime < closingMinutes;
  }

  return currentTime >= openingMinutes && currentTime < closingMinutes;
};