// Nav
type Link_Props = {
    index: number,
    text: string,
    url: string
}

export const navlinks: Link_Props[] = [
    { index: 0, text: "features", url:"features-section" },
    { index: 1, text: "reviews", url:"reviews-section" },
    { index: 2, text: "contact", url:"contact-section" },
]

export const time_ = [ "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am", "12pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm", "12am"];
